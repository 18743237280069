import { v4 as uuid } from 'uuid';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import _ from 'underscore';
import {
  Button, Modal, Header, Label, Input, Icon, Dropdown, Progress, Table, Checkbox,
} from 'semantic-ui-react';
import bind from 'bind-decorator';
import pluralize from 'pluralize';
import GroupSummary from '@/models/group_summary';
import Group from '@/models/group';
import User from '@/models/user';
import LearningGroupAssignment from '@/models/learning_group_assignment';
import CourseSchedule from '@/models/course_schedule';
import ModelTable from '../Shared/ModelTable';
import {
  GROUP_TYPE_LEARNING, GROUP_TYPE_COMPLIANCE, Features, GROUP_TYPE_SMART,
} from '../../constants';
import { colorForGroupNameLength } from '../utils/DisplayHelpers';

class GroupListContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showCreateModal: false,
      showDeleteModal: false,
      showCloneModal: false,
      showCloneProgress: false,
      cloningProgress: 0,
      selectedGroups: [],
      groupNameLength: 0,
      cloneGroupNameLength: 0,
      modalTableKey: uuid(),
      showClone: false,
    };
  }

  @bind
  toggleCreateModal() {
    this.setState({
      showCreateModal: !this.state.showCreateModal,
      groupNameLength: 0,
    });
  }

  @bind
  async createGroup() {
    const groupName = this.state.groupName.trim();
    await Group.objects().create({ name: groupName });
    this.setState(
      {
        modalTableKey: uuid(),
      },
      this.toggleCreateModal,
    );
  }

  @bind
  async fetchGroups({
    perPage, filter, search,
  }) {
    const user = await User.me();
    const groupTypes = user.waffle_flags.includes(Features.COMPLIANCE_TRAINING)
      ? [GROUP_TYPE_LEARNING, GROUP_TYPE_SMART, GROUP_TYPE_COMPLIANCE]
      : [GROUP_TYPE_LEARNING, GROUP_TYPE_SMART];

    this.setState({
      showClone: user.waffle_flags.includes(Features.GROUP_CLONING),
    });

    const allData = await GroupSummary.objects()
      .filtered({ deleted: false, group_type: groupTypes })
      .filtered(filter)
      .search(search)
      .all();

    allData.sort((a, b) => {
      if (a.group_type === GROUP_TYPE_COMPLIANCE) {
        return -1;
      }
      if (b.group_type === GROUP_TYPE_COMPLIANCE) {
        return 1;
      }
      return a.name.localeCompare(b.name, 'en', { sensitivity: 'base' });
    });
    const data = allData.slice(0, perPage);

    return [allData, data];
  }

  @bind
  async doDeleteGroups() {
    const groups = this.state.selectedGroups;
    const actions = groups.map((group) => Group.objects().update(group.id, { deleted: true }));
    await Promise.all(actions);
    this.setState({
      modalTableKey: uuid(),
      showDeleteModal: false,
    });
  }

  @bind
  async doCloneGroup() {
    this.setState({
      showCloneModal: false,
      showCloneProgress: true,
      cloningProgress: 0,
    });
    const cloneFrom = this.state.selectedGroups[0];
    const dolly = await Group.objects().create({
      name: this.state.cloneGroupName,
    });

    const schedules = await CourseSchedule.objects()
      .filtered({ organizational_group_id: cloneFrom.id, deleted: false })
      .all();
    const assignments = await LearningGroupAssignment.objects()
      .filtered({ organizational_group_id: cloneFrom.id, deleted: false })
      .all();

    const total = schedules.length + assignments.length;
    let completed = 0;
    let actions = schedules.map((schedule) => {
      const data = _.extend(schedule, { id: null, organizational_group_id: dolly.id });
      Object.keys(data).forEach((key) => {
        if (data[key] === null) {
          delete data[key];
        }
      });
      return CourseSchedule.objects()
        .create(data)
        .then(() => {
          completed += 1;
          this.setState({
            cloningProgress: (completed / total) * 100.0,
          });
        });
    });

    actions = actions.concat(
      assignments.map((assignment) => {
        const data = {
          user_id: assignment.user_id,
          group_id: dolly.id,
        };
        return LearningGroupAssignment.objects()
          .create(data, 'assign')
          .then(() => {
            completed += 1;
            this.setState({
              cloningProgress: (completed / total) * 100.0,
            });
          });
      }),
    );

    await Promise.all(actions);
    this.setState({
      showCloneProgress: false,
      modalTableKey: uuid(),
    });
  }

  @bind
  menuOptionsForGroup(group) {
    if (group.group_type === GROUP_TYPE_COMPLIANCE) {
      return null; // you cannot edit this group
    }
    return (
      <Dropdown.Menu>
        { this.state.showClone ? (
          <Dropdown.Item
            text="Clone"
            onClick={() => {
              const name = `${group.name} CLONE`;
              this.setState({
                showCloneModal: true,
                cloneGroupNameLength: name.trim().length,
                cloneGroupName: name,
                selectedGroups: [group],
              });
            }}
          />
        ) : null }
        <Dropdown.Item
          text="Delete"
          onClick={() => {
            this.setState({
              showDeleteModal: true,
              selectedGroups: [group],
            });
          }}
        />
      </Dropdown.Menu>
    );
  }

  @bind
  selectionChanged(selectedGroups) {
    this.setState({ selectedGroups });
  }

  @bind
  stopDeleting() {
    const { selectedGroups } = this.state;
    selectedGroups.forEach((group) => delete group.checked);
    this.setState({
      selectedGroups,
      showDeleteModal: false,
    });
  }

  render() {
    const { selectedGroups } = this.state;
    const columnData = [
      {
        name: 'Group Name',
        search: ['name'],
        key: [
          'name',
          'id',
          'group_type',
          (name, id, groupType) => (
            <React.Fragment>
              <Link to={`/app/v2/groups/${id}`}>{name}</Link>
              {' '}
              {groupType === GROUP_TYPE_COMPLIANCE ? <Icon name="lock" /> : null}
            </React.Fragment>
          ),
        ],
      },
      {
        name: 'Learners',
        key: 'learner_count',
      },
      {
        name: 'Courses',
        key: 'course_count',
      },
      {
        name: 'Lessons',
        key: 'lesson_count',
      },
      {
        name: 'Type',
        key: [
          'group_type',
          (groupType) => (
            <p>{groupType !== GROUP_TYPE_LEARNING ? 'Smart Group' : 'Standard Group'}</p>
          ),
        ],
      },
      {
        name: 'Actions',
        key: [
          'this',
          (group) => {
            const menu = this.menuOptionsForGroup(group);
            if (menu) {
              return (
                <Dropdown icon="ellipsis vertical" floating labeled className="icon">
                  {menu}
                </Dropdown>
              );
            }
            return null;
          },
        ],
      },
    ];

    return (
      <div className="hickory-container">
        <ModelTable
          key={this.state.modalTableKey}
          title="Groups"
          baseFilter={{ deleted: false }}
          model={GroupSummary}
          columns={columnData}
          updateData={this.fetchGroups}
          select
          selectionChanged={this.selectionChanged}
          selectionFilter={(group) => group.group_type !== GROUP_TYPE_COMPLIANCE}
        >
          <ModelTable.ActionButton
            name="Add Group"
            className="primary-button"
            method={this.toggleCreateModal}
          />
          <ModelTable.Actions>
            <ModelTable.Action
              name={`Delete ${pluralize('Group', selectedGroups.length)}`}
              method={() => this.setState({ showDeleteModal: true })}
              requireSelected
            />
          </ModelTable.Actions>
        </ModelTable>

        <Modal
          open={this.state.showCreateModal}
          onClose={this.toggleCreateModal}
          style={{ width: '500px' }}
          closeIcon
        >
          <Modal.Header>Create a New Group</Modal.Header>
          <Modal.Content>
            <Header as="h3">Group Name:</Header>
            <div>
              <Input
                placeholder="Enter Group Name (required)"
                style={{ padding: '10px' }}
                value={this.state.groupName}
                onChange={(e, { value }) => {
                  const length = value.trim().length;
                  if (length <= 80) {
                    this.setState({
                      groupName: value,
                      groupNameLength: value.trim().length,
                    });
                  }
                }}
              />
            </div>
            <Label
              content={`${this.state.groupNameLength}/50 characters`}
              color={colorForGroupNameLength(this.state.groupNameLength)}
              size="small"
            />
          </Modal.Content>
          <Modal.Actions>
            {this.state.groupName
            && this.state.groupNameLength > 0
            && this.state.groupNameLength <= 96 ? (
              <Button onClick={this.createGroup} color="green">
                Create
              </Button>
              ) : (
                <Button disabled color="grey">
                  Create
                </Button>
              )}
          </Modal.Actions>
        </Modal>

        <Modal
          open={this.state.showDeleteModal}
          onClose={this.stopDeleting}
          style={{ width: '500px' }}
          closeIcon
        >
          <Modal.Header>Delete Group</Modal.Header>
          <Modal.Content>
            You are deleting the following groups. THIS ACTION CANNOT BE UNDONE.
            Please check the box next to each group to confirm its deletion.
            <Table celled>
              <Table.Body>
                {selectedGroups.map((group) => (
                  <Table.Row>
                    <Table.Cell collapsing>
                      <Checkbox
                        onChange={() => {
                          group.checked = true;
                          this.setState({ selectedGroups });
                        }}
                        checked={group.checked}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      {group.name}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={this.stopDeleting}
            >
              Cancel
            </Button>
            <Button
              onClick={this.doDeleteGroups}
              color="red"
              disabled={!_.every(_.pluck(selectedGroups, 'checked'))}
            >
              Delete
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal
          open={this.state.showCloneModal}
          onClose={() => {
            this.setState({ showCloneModal: false });
          }}
          style={{ width: '500px' }}
          closeIcon
        >
          <Modal.Header>Clone Group</Modal.Header>
          <Modal.Content>
            Name:
            <br />
            <Input
              value={this.state.cloneGroupName}
              style={{ width: '100%' }}
              onChange={(e, { value }) => {
                const cloneGroupNameLength = value.trim().length;
                if (cloneGroupNameLength <= 80) {
                  this.setState({ cloneGroupName: value, cloneGroupNameLength });
                }
              }}
            />
            <Label
              content={`${this.state.cloneGroupNameLength}/50 characters`}
              color={colorForGroupNameLength(this.state.cloneGroupNameLength)}
              size="small"
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() => {
                this.setState({ showCloneModal: false });
              }}
            >
              Cancel
            </Button>
            { this.state.cloneGroupNameLength > 0 && this.state.cloneGroupNameLength <= 80 ? (
              <Button onClick={this.doCloneGroup} color="green">
                Clone
              </Button>
            ) : (
              <Button onClick={this.doCloneGroup} color="grey" disabled>
                Clone
              </Button>
            )}
          </Modal.Actions>
        </Modal>

        <Modal open={this.state.showCloneProgress} style={{ width: '500px' }}>
          <Modal.Header>Cloning Group</Modal.Header>
          <Modal.Content>
            <Progress color="green" percent={this.state.cloningProgress} />
          </Modal.Content>
          <Modal.Actions />
        </Modal>
      </div>
    );
  }
}

export default GroupListContainer;
