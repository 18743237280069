import ls from 'local-storage'
import pluralize from 'pluralize'
import _ from 'underscore'
import moment from 'moment'
import bind from 'bind-decorator'
import { Model } from '@/models/model'
import apiRequest from './api_request'
import SumoLogger from 'sumo-logger'
import { SUMO } from '../constants';
import { get_model } from './model'

const sumoLogger = new SumoLogger({endpoint: SUMO})

const BASE_URL = window.location.origin + '/api/v2/'
const CACHE_LIFE = 300 //seconds

var database = {} //lol


var ws = null;

export const OPERATORS = {
  gte: '>=',
  lte: '<=',
  gt: '>',
  lt: '<',
  not: '!='
}

function setupWebSocket(orgId){
  var protocol = window.location.protocol == "http:" ? "ws://" : "wss://";
  ws = new WebSocket(protocol + window.location.host + "/api/v2/ws/cache-" + orgId + "?subscribe-broadcast");

  ws.onerror = function(event) {
    console.error("WebSocket error observed:", event);
  };

  ws.onclose = function(){
    // To avoid the scenario where the webserver has an issue, and then
    // immediately we begin DDoSing ourselves, we should add a random delay
    // to the client's reconnection. This at least prevents all clients from
    // reconnecting simultaneously.
    var delay = 5 + parseInt(Math.random() * 25);
    console.error("Disconnected from websocket. Sleeping " + delay + " seconds and attempting reconnect.");
    setTimeout(() => {
      setupWebSocket(orgId);
    }, delay * 1000);
  };

  ws.onmessage = function (event) {
    var data = JSON.parse(event.data);
    var modelName = data.model;
    var modelId = data.id;

    //1. check to see if we have a cache for the model
    var c = cache(modelName);
    //2. check to see if we have a model with that id
    var model = _.findWhere(c.get(), {id: modelId});
    //3. if so, call .get(id) to re-load that model
    if(model){
      var delay = 1 + parseInt(Math.random() * 5);
      setTimeout(() => {
        Model(modelName).objects().get(modelId);
      }, delay * 1000);
    }
  };
}

// setTimeout(() => {
//   Model("user").me().then( (user) =>{
//     setupWebSocket(user.organization_id);
//   });
// }, 3000); //wait three seconds and then attempt first connection.
//           //this just simply lowers the burden on the web browser
//           //when initially loading - there is no other reason to delay.

function cache(klassOrString) {
  if((typeof klassOrString) == 'string'){
    return new dictCache(klassOrString);
  }
  return new dictCache(klassOrString.namespace);
}

class dictCache {
  constructor(namespace) {
    this.namespace = namespace;
  }


  valid() {
    if (this.namespace in database) {
      return true
    }
    return false
  }

  isFullySet(filters = {}) {
    if(!this.valid()){
      return false;
    }
    if(database[this.namespace]['lastset']){
      return true;
    }
    // var loadedFilters = database[this.namespace]['filters'] ? database[this.namespace]['filters'] : [];
    // for(var i = 0; i != loadedFilters.length; i++){
    //   var otherFilter = loadedFilters[i];
    //   if(_.isEqual(otherFilter, filters)){
    //     return true;
    //   }
    // }
    return false;
  }

  set(data, partial = false, filters = {}) {
    var now = moment();
    var lastUpdate = database[this.namespace] ? database[this.namespace].lastset : null;
    var timer = database[this.namespace] ? database[this.namespace].timer : null;
    var allFilters = database[this.filters] ? database[this.filters] : [];
    if(partial){
      allFilters.push(filters);
    }else{
      allFilters = [];
    }
    if(timer){
      clearTimeout(timer);
    }
    var expiration = partial ? (CACHE_LIFE * 200) : (CACHE_LIFE * 1000);
    database[this.namespace] = {
      data: data,
      lastset: partial ? lastUpdate : now,
      filters: allFilters,
      timer: setTimeout( () => {
        console.log("Purging cached records for " + this.namespace);
        delete database[this.namespace];
      }, expiration)
    };
  }

  get() {
    return database[this.namespace]['data']
  }

  truncate(){
    console.debug("Truncating cache for "+ this.namespace);
    database[this.namespace] = {
      'data': [],
    };
  }

  @bind
  update(data) {
    if (this.valid()) {
      let temp = this.get()
      let index = _.findIndex(temp, (item) => {
        return data.id == item.id
      })
      if (index >= 0) {
        _.extend(temp[index], data)
      } else {
        temp.push(data)
      }
      this.set(temp, true) //partial set
    }
  }

  clear() {
    delete database[this.namespace]
  }
}

class ModelQuery {
  constructor(klass) {
    this.klass = klass
    this.namespace = klass.namespace
    this.noData = false
    this.allUsers = []
    this.data = [];
    this.api_base = klass.path
        ? klass.path
        : BASE_URL + pluralize.plural(this.namespace);

    this.cache = cache(this.klass);
    if (!cache(this.klass).valid()) {
      cache(this.klass).set([], true);
    }
  }

  _set(data) {
    this.data = data;
  }

  all() {
    return this.data;
  }

  async _hydrate(rawData) {
    var result = [];
    for(var i = 0; i != rawData.length; i++){
      var obj = _.extend(new this.klass(), rawData[i]);
      if(obj.didLoad){
        await obj.didLoad();
      }
      result.push(obj);
    }
    return result;
  }

  filtered(conditions) {

    if (_.isEmpty(conditions)) {
      return this
    }
    var data = this.data;
    if (_.isEmpty(this.data) && !this.noData) {
      data = this.cache.get()
    }
    data = data.filter((item) => {
      var test = true
      for (var key in conditions) {
        // preface a filter key with '_' if it's for the view and doesn't actually exist on the model
        if (key.startsWith('_')) continue
        let operator = null
        const condition_value = conditions[key];
        var item_value;

        if(key.includes(".")){
          // if the supplied key contains a dot then they're asking
          // to filter by a related object's property.
          var parts = key.split(".");
          if(parts.length != 2){
            console.error("Unsupported related object query: '" + key + "'");
          }else{
            var relatedObjName = parts[0];
            var relatedObjKey = parts[1];
            item_value = item[relatedObjName];
            if(Array.isArray(item_value)){
              item_value = _.map(item_value, (item) => { return item[relatedObjKey]; });
            }else{
              item_value = item_value[relatedObjKey];
            }
          }
        } else if (key.includes('__')) {
          let keySplit = key.split('__')
          operator = OPERATORS[keySplit.pop()]
          if (operator) key = keySplit.join('__')
          item_value = item[key]

        }else{
          item_value = item[key];
        }

        if (_.isFunction(condition_value)) {
          console.warn("Deprecated filter type - avoid passing functions in the future.");
          if (!condition_value(item)) {
            test = false;
          }
        } else if (Array.isArray(condition_value)){
          // crappy logic below
          // in short, if item_value is in condition_value
          // we want to set test to false IF the presence of
          // item_value is in condition_value is expected.
          // e.g. 1 in [1, 2, 3] is skipped
          //      1 not in [1, 2, 3] is false
          if(condition_value.includes(item_value) === (operator === '!=')){
            test = false;
          }
        } else if (Array.isArray(item_value)) {
          // more crappy logic (ignore)
          if(item_value.includes(condition_value) === (operator === '!=')){
            test = false;
          }
        } else if (operator) {
          test = eval('item_value' + operator + 'condition_value')
        } else if (item_value != condition_value) {
          test = false
        }
      }
      return test
    })
    if (_.isEmpty(data)) {
      this.noData = true
    }
    this._set(data)
    return this
  }

  async join(joinData) {
    var data = this.data;
    if (_.isEmpty(this.data) && !this.noData) {
      data = this.cache.get();
    }

    if (!this.noData) {
      for (const join of Object.entries(joinData)) {
        const [model_name, fields] = join
        const model = get_model(model_name)
        const origin_field = (model.keyname || model_name) + '_id'
        let ids = _.uniq(_.pluck(data, origin_field))
        let actions = []
        let otherData = []
        let chunks = 0
        while (ids.length) {
          chunks++
          const id_slice = ids.slice(0, 48)
          ids = ids.slice(48)
          actions.push(model.objects().filtered({id: id_slice}).all().then((data) => otherData = otherData.concat(data)))
        }
        if (chunks > 1) console.warn(`Warning: Earl is the size of ${chunks} men`)
        await Promise.all(actions)
        otherData = _.groupBy(otherData, 'id')
        data = data.map((dat) => {
          if (Object.keys(otherData).includes(dat[origin_field])) {
            const otherDat = otherData[dat[origin_field]][0]
            for (const field of fields) {
              let new_field = `${model_name}__${field}`
              dat[new_field] = otherDat[field]
            }
          }
          return dat
        })
      }
    this._set(data)

    }

    return this
  }


  search(query) {
    if(!query || Object.keys(query) == 0){
      return this;
    }

    var data = this.data;
    if (_.isEmpty(this.data) && !this.noData) {
      data = this.cache.get();
    }
    data = data.filter((item) => {
      let count = 0;
      var fields = Object.keys(query);
      for (var i = 0; i < fields.length; i++) {
        var field = fields[i]
        var fieldValue = item[field];
        if (fieldValue){
          var keywords = query[field];
          fieldValue = fieldValue.toString().toLowerCase();
          if (!Array.isArray(keywords)) keywords = [keywords]
          for(var j = 0; j != keywords.length; j++){
            var keyword = keywords[j].toLowerCase();
            if(fieldValue.includes(keyword)){
              count++;
              if (count === keywords.length) return true;
            }
          }
        }
      }
      return false
    })
    if (_.isEmpty(data)) {
      this.noData = true
    }
    this._set(data)

    return this
  }

  sorted(column, ascending) {
    var mod = ascending ? 1 : -1
    var data = this.data;
    if (_.isEmpty(this.data) && !this.noData) {
      data = this.cache.get();
    }
    var type = null;
    data = data.sort((a, b) => {
      var check1 = a[column];
      var check2 = b[column];
      if(!type){
        // to avoid re-computing types on every check, let's only check
        // if type is null. However, since not every row will have data
        // for a given column, we shouldn't set a default - just leave it
        // null if the following checks fail.
        if(column != "name" && (moment(check1).isValid() || moment(check2).isValid())){
          type = "date";
        }else if(typeof check1 == "string"){
          type = "string";
        }
      }

      if (type == "date") {
        check1 = moment(check1).unix() || Number.MAX_VALUE;
        check2 = moment(check2).unix() || Number.MAX_VALUE;
      } else if(type == "string"){
        if(ascending){
          return check1.localeCompare(check2, 'en', {'sensitivity': 'base'});
        }else{
          return check2.localeCompare(check1, 'en', {'sensitivity': 'base'});
        }
      }
      if (check1 > check2) return 1 * mod;
      if (check1 < check2) return -1 * mod;
      return 0;
    });
    if (_.isEmpty(data)) {
      this.noData = true;
    }
    this._set(data);
    return this;
  }

  page(page, perPage = 100) {
    var data = this.data;
    if (_.isEmpty(this.data) && !this.noData) {
      data = this.cache.get();
    }
    if (page) {
      data = data.slice(
        (page - 1) * perPage,
        Math.min(page * perPage, data.length)
      );
      if (_.isEmpty(data)) {
        this.noData = true;
      }
      this._set(data);
    }
    return this.data;
  }

  _getHeaders(additional) {
    return _.extend({
      Authorization: 'Bearer ' + ls('token.access'),
    }, additional)
  }

  async _handleResponse(response) {
    if (response.ok) {
      return await response.json()
    } else {
      const response_data = await response.json()
      let error_data
      if (typeof response_data === 'string') {
        error_data = {"non_field_errors": [response_data]}
      } else if (typeof response_data.detail === 'string') {
        error_data = {"non_field_errors": [response_data.detail]}
      } else {
        error_data = response_data
      }
      throw {data: error_data}
    }
  }

  async init(filters) {
    var canHitCacheFunc = () => {
      var data = this.cache.get(); // hit cache first
      var shouldHitCache = data && data.length > 0 && this.cache.isFullySet(filters);
      if ('cache' in this.klass && !this.klass.cache) {
        shouldHitCache = false
      }
      return shouldHitCache;
    };

    var hitCacheFunc = async () => {
      var data = this.cache.get();
      if (_.isEmpty(data)) {
        this.noData = true;
      }
      this._set(data);
      return this;
    };

    var hitApiFunc = async () => {
      var data = this.cache.get(); // hit cache first
      var url = this.api_base;

      var partialFill = false;
      if(filters && Object.keys(filters).length > 0){
        var params = new URLSearchParams(filters);
        var newUrl = url + "?" + params;
        if(newUrl.length <= 2000){
          url = newUrl;
          partialFill = !(Object.keys(filters).length == 1 && filters["deleted"] == false);
        }else{
          console.error("URL too long. ABORT!", newUrl);
          // const aFit = Error("EARL IS TOO FAT!");
          // throw aFit;
        }
      }

      var response = await fetch(url, {
        headers: this._getHeaders()
      });
      if (response.status === 500) {
        const e = new Error();
        sumoLogger.log({url, stack: e.stack, user: Model("user").getCurrentUserId(), namespace: this.namespace})
      }
      var rawData = await response.json()
      data = await this._hydrate(rawData)
      this.cache.set(data, partialFill, filters);
      if (_.isEmpty(data)) {
        this.noData = true;
      }
      this._set(data)
      return this
    };

    if(canHitCacheFunc()){
      return await hitCacheFunc();
    }

    return await apiRequest(this.namespace, hitApiFunc, canHitCacheFunc, hitCacheFunc);
  }

  async get(id) {

    var hitApiFunc = async () => {
      if (id === undefined || id === 'undefined') {
        const e = new Error();
        sumoLogger.log(e.stack)
        return null; // no need to hit the API, we know what will happen next
      }

      const url = this.api_base + '/' + id;
      const response = await fetch(url, {
        headers: this._getHeaders(),
      });
      if(!response.ok){
        return null; // HACK: we should instead fix _handleResponse to not throw an exception
      }
      var result = await this._handleResponse(response);
      if(result){
        this._invalidateRelatedCaches(result);
        var objs = await this._hydrate([result]);
        var obj = objs[0];
        this.cache.update(obj);
        return obj;
      }
      return null;
    };

    return await apiRequest(this.namespace, hitApiFunc);
  }

  _addUrlParams(url, urlParams) {
    if (!_.isEmpty(urlParams)) {
      url += '?'
      url += Object
      .keys(urlParams)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(urlParams[key])}`)
      .join('&')
    }
    return url
  }

  _invalidateRelatedCaches(objOrObjsOrNull){
    // this function is currently a bit of a sledge hammer
    // ideally we'd inspect the param and:
    // 1. if single obj or objs, then only invalidate
    //    the minimum amount of cache necessary for those objects
    // 2. if param is null, then proceed with the below implementation
    if(this.klass.related){
      var related = Object.keys(this.klass.related);
      for(var i = 0; i != related.length; i++){
        cache(related[i]).truncate(); // sledge hammer
      }
    }
  }

  async create(propsOrArray, urlPlus = null, urlParams = null) {
    var hitApiFunc = async () => {
      let url = this.api_base
      if (urlPlus) {
        url = url + '/' + urlPlus
      }
      url = this._addUrlParams(url, urlParams)

      const bulk = Array.isArray(propsOrArray);
      if (bulk) {
        url += "/bulk"
      }
      if (!urlParams) url += '/'

      const response = await fetch(url, {
        method: 'POST',
        headers: this._getHeaders({
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(propsOrArray),
      })

      let result = await this._handleResponse(response);
      if (result && !urlPlus) {
        // do not cache if urlPlus was supplied as we can't be sure if
        // this belongs to the same model.
        this._invalidateRelatedCaches(objs);
        let objs = await this._hydrate(bulk ? result : [result])
        objs.forEach((obj) => this.cache.update(obj))
        return bulk ? objs : objs[0]
      }else{
        this._invalidateRelatedCaches();
      }
      return result;
    };
    return await apiRequest(this.namespace, hitApiFunc);
  }

  async update(id, props) {
    var hitApiFunc = async () => {
      const url = this.api_base + '/' + id + "/";
      const response = await fetch(url, {
        method: 'PATCH',
        headers: this._getHeaders({
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(props),
      })
      var result = await this._handleResponse(response);
      if(result){
        this._invalidateRelatedCaches(result);
        var objs = await this._hydrate([result]);
        var obj = objs[0];
        this.cache.update(obj);
        return obj;
      }
      return null;
    };
    return await apiRequest(this.namespace, hitApiFunc);
  }
}


export default ModelQuery;
