import React, { Component } from 'react';
import Radium from 'radium';
import bind from 'bind-decorator';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {
  Button, Header, Message, Loader,
} from 'semantic-ui-react';
import moment from 'moment';
import SumoLogger from 'sumo-logger';
import _ from 'underscore';
import User from '@/models/user';
import UserLessonStatus from '@/models/user_lesson_status';
import CardContainer from '../Shared/CardContainer';
import LessonLibraryCard from '../LearnerLessons/LessonLibraryCard';
import { SUMO } from '../../constants';
import CourseLibraryCard from '../LearnerLessons/CourseLibraryCard';
import SideScroller from '../Shared/SideScroller';
import UserCourseStatus from '../../models/user_course_status';

const sumoLogger = new SumoLogger({ endpoint: SUMO });

const s = {
  container: {
    padding: '0 20%',
    marginTop: '20px',
  },
  cardContainerBody: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    marginTop: '20px',
  },
  chartContainer: {
    height: '210px',
    width: '210px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  dashboardCard: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '210px',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  startBox: {
    display: 'flex',
    flexDirection: 'column',
    background: '#F2F3F4',
    padding: '30px',
    height: 'fit-content',
  },
};

Highcharts.wrap(Highcharts.seriesTypes.pie.prototype, 'animate', function (proceed, init) {
  this.startAngleRad += Math.PI;
  proceed.call(this, init);
});

class LearnerHomeContainer extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);

    this.chartComponent = React.createRef();
    this.state = {
      incompleteAssignments: [],
      loading: true,
    };
  }

  async componentDidMount() {
    let hasExtension = true;
    try {
      chrome.runtime.sendMessage(
        'fhmmnaiblknlojkbkdhhcoialcibgedp',
        { message: 'version' },
        (reply) => {
          if (reply) {
            if (reply.version) {
              hasExtension = true;
            }
          } else {
            hasExtension = false;
          }
        },
      );
    } catch {
      hasExtension = true;
    } finally {
      // This browser is not chrome so set a flag so we know to bail out of any chrome extension messaging attempts
      window.isChrome = false;
    }
    this.setState({
      hasExtension,
    });
    const me = await User.me();
    let assignments = await UserLessonStatus.objects()
      .filtered({ user_id: me.id, available_to_take: true })
      .join('lesson__name', 'lesson__id', 'lesson__card_count', 'lesson__median_time_spent', 'lesson__lesson_type')
      .all();
    assignments = _.groupBy(assignments, 'completed');
    const courses = await UserCourseStatus.objects().filtered({ available_to_take: true }).all();
    const coursesCount = courses.length;

    const courseLessons = new Set();
    courses.forEach((course) => {
      _.pluck(course.lesson_path, 'lesson_id').forEach(courseLessons.add, courseLessons);
    });
    let lessons = assignments.false || [];
    lessons = lessons.filter((lesson) => !Array.from(courseLessons).includes(lesson.lesson_id));
    const completedThisWeek = await UserLessonStatus.objects()
      .filtered({
        user_id: me.id,
        available_to_take: true,
        completed: true,
        last_completed_at__gte: moment().subtract({ days: 7 }).toISOString(),
      })
      .all();
    const reviews = (assignments.true || []).filter(
      (assignment) => moment(assignment.next_review_date).isBefore(moment()),
    );
    const reviewsCount = reviews.length;
    const lessonsCount = lessons.length;
    // incompleteAssignments = incompleteAssignments.sort((a, b) => {
    //   let aDate = null;
    //   let bDate = null;

    //   let isReview = !!a.cards_available_to_review.length;
    //   aDate = isReview
    //     ? moment.min(a.cards_available_to_review.map((r) => moment(r.scheduled_review_date)))
    //     : moment(a.localized_start_date);
    //   isReview = !!b.cards_available_to_review.length;
    //   bDate = isReview
    //     ? moment.min(b.cards_available_to_review.map((r) => moment(r.scheduled_review_date)))
    //     : moment(b.localized_start_date);
    //   return aDate.diff(bDate);
    // });
    let completeCount = completedThisWeek.length;
    let totalCount = assignments.length;
    if (totalCount === 0) {
      completeCount = 1;
      totalCount = 1;
    }

    this.setState(
      {
        incompleteAssignments: lessons,
        lessonsCount,
        reviewsCount,
        completeCount,
        coursesCount,
        totalCount,
        loading: false,
        courses,
        reviews,
        lessons,
      },
      () => {
        const container = this.chartComponent.current.container.current;

        container.style.height = '100%';
        container.style.width = '100%';
        this.chartComponent.current.chart.reflow();
      },
    );
  }

  handleClick() {
    window.open(
      'https://chrome.google.com/webstore/detail/hickory/fhmmnaiblknlojkbkdhhcoialcibgedp',
      '_blank',
    );
  }

  @bind
  startLessons() {
    if (this.state.incompleteAssignments[0].lesson_id === undefined) {
      sumoLogger.log(
        `
        ERROR: missing lesson_id for UserLessonStatus
        ${this.state.incompleteAssignments[0].id} in LearnerHomeContainer.jsx
        `,
      );
    }
    window.location.href = `/app/v2/lessons/${this.state.incompleteAssignments[0].lesson_id}/player`;
  }

  render() {
    if (this.state.loading) {
      return (
        <div style={s.container}>
          <Loader active inline="centered" />
        </div>
      );
    }
    const percentComplete = this.state.completeCount / this.state.totalCount;
    const incompleteText = `<div style='font-size: 40px'>
          ${(percentComplete * 100).toFixed()}%
          </div> Weekly Work Complete`;
    const completeText = `
    <div class="checkmark-icon">
      <svg width="100%" height="100%" viewBox="0 0 256 256">
        <polyline stroke='#0dd07b' points="206.97 70.79 94.26 204.28 55.03 155.06"/>
      </svg>
    </div>`;
    const options = {
      title: {
        text: '',
      },
      subtitle: {
        text: percentComplete < 1 ? incompleteText : completeText,
        align: 'center',
        verticalAlign: 'middle',
        style: {
          textAlign: 'center',
        },
        x: 0,
        y: 0,
        useHTML: true,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
          },
          showInLegend: false,
          size: 200,
          borderWidth: 0,
        },
      },
      series: [
        {
          type: 'pie',
          enableMouseTracking: false,
          innerSize: '90%',
          dataLabels: {
            enabled: false,
          },
          data: [
            {
              y: this.state.totalCount - this.state.completeCount,
              color: '#e3e3e3',
            },
            {
              y: this.state.completeCount,
              color: '#0dd07b',
            },
          ],
        },
      ],
    };

    return (
      <div style={s.container}>
        <div>
          {!this.state.hasExtension && (
            <Message info onClick={this.handleClick} style={{ cursor: 'pointer' }}>
              Please install the Hickory Chrome Extension to improve your learning ability.
            </Message>
          )}
          <CardContainer>
            <div style={s.dashboardCard}>
              <div style={s.row}>
                <div style={s.chartContainer}>
                  <HighchartsReact
                    ref={this.chartComponent}
                    highcharts={Highcharts}
                    options={options}
                  />
                </div>
                <div style={s.column}>
                  <Header as="h2">Learning Dashboard</Header>
                  <Header as="h3">Your Assignments</Header>
                  {`${this.state.coursesCount} Courses, ${this.state.lessonsCount} Lessons,
                  ${this.state.reviewsCount} Reviews`}
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={s.startBox}>
                  <Button positive onClick={this.startLessons} disabled={!this.state.incompleteAssignments.length}>
                    Start Assignments
                  </Button>
                  <span style={{ color: '#7E7F8F' }}>You can start and stop at any time</span>
                </div>
              </div>
            </div>
          </CardContainer>
        </div>
        {!!this.state.courses.length && (
        <SideScroller title="Courses:">
          {this.state.courses.map((course) => (
            <CourseLibraryCard key={course.id} course={course} />
          ))}
        </SideScroller>
        )}
        {!!this.state.lessons.length && (
        <SideScroller title="Lessons:">
          {this.state.lessons.map((record) => (
            <LessonLibraryCard key={record.id} lessonStatus={record} />
          ))}
        </SideScroller>
        )}
        {!!this.state.reviews.length && (
        <SideScroller title="Reviews:">
          {this.state.reviews.map((record) => (
            <LessonLibraryCard key={record.id} lessonStatus={record} />
          ))}
        </SideScroller>

        )}
      </div>
    );
  }
}

export default Radium(LearnerHomeContainer);
