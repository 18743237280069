import React, { Component } from 'react';
import Radium from 'radium';
import bind from 'bind-decorator';
import { v4 as uuid } from 'uuid';
import {
  Button, Modal, Input, Dropdown, Loader, Checkbox,
} from 'semantic-ui-react';
import SumoLogger from 'sumo-logger';
import Lesson from '@/models/lesson';
import Organization from '@/models/organization';
import User from '@/models/user';
import LessonsList from '../Shared/LessonsList';
import {
  SUMO,
  Features,
  ARTICLE,
  VIDEO,
  CARD,
} from '../../constants';

const sumoLogger = new SumoLogger({ endpoint: SUMO });

let s = {};

class LessonListContainer extends Component {
  constructor(props) {
    super(props);

    const popupPreview = JSON.parse(localStorage.getItem('popupPreview'));

    this.state = {
      lessonListKey: uuid(),
      scorm_enabled: false,
      articles_enabled: false,
      video_lessons_enabled: false,
      uploading_scorm: false,
      popupPreview: popupPreview === null ? true : popupPreview,

    };
    this.fileInput = React.createRef();
  }

  async componentDidMount() {
    const user = await User.me();
    const organization = await Organization.objects().get(user.organization_id);
    this.setState({
      scorm_enabled: organization.scorm_enabled,
      articles_enabled: user.waffle_flags.includes(Features.ARTICLES),
      video_lessons_enabled: user.waffle_flags.includes(Features.VIDEO_LESSONS),
    });
  }

  @bind
  async createLesson(lessonType) {
    const lessonName = this.state.newLessonName.trim();
    const newLesson = await Lesson.objects().create({ name: lessonName, lesson_type: lessonType });
    if (newLesson.id === undefined) {
      sumoLogger.log(`ERROR: missing lesson_id for new Lesson ${this.state.newLessonName} in LessonListContainer.jsx`);
    }
    window.location.href = `/app/v2/lessons/${newLesson.id}/editor`;
  }

  @bind
  async createSCORMLesson() {
    const lessonName = this.state.newLessonName.trim();
    const newLesson = await Lesson.objects().create({ name: lessonName, lesson_type: 2 });

    const file = this.fileInput.current.files[0];
    this.setState({ uploading_scorm: true }, async () => {
      const response = await newLesson.uploadSCORMContent(file);
      if (response.error) {
        await Lesson.objects().update(newLesson.id, { deleted: true });
        const error = response.error;
        this.setState({
          uploading_scorm: false,
          scorm_error: error,
        });
      } else {
        this.setState({
          uploading_scorm: false,
          showSCORMUploadModal: false,
          lessonListKey: uuid(), // force refresh
        });
      }
    });
  }

  @bind
  togglePopup(e) {
    const { popupPreview } = this.state;
    this.setState({ popupPreview: !popupPreview });
    localStorage.setItem('popupPreview', !popupPreview);
    e.preventDefault();
  }

  render() {
    const regrettably = true;
    const { popupPreview } = this.state;

    return (
      <div style={s.body}>
        <div style={s.container}>
          <div style={{ width: '100%' }}>
            <div style={s.header}>
              <h2 style={{ marginBottom: '0px' }}>Lessons</h2>
              <div className="row">
                <Dropdown text="Create New..." button className="green">
                  <Dropdown.Menu>
                    {this.state.articles_enabled && (
                    <Dropdown.Item
                      text="Article-based Lesson"
                      onClick={() => this.setState({ showCreateLessonModal: true, createLessonType: ARTICLE })}
                    />
                    )}
                    <Dropdown.Item
                      text="Card-based Lesson"
                      onClick={() => this.setState({ showCreateLessonModal: true, createLessonType: CARD })}
                    />
                    {this.state.scorm_enabled && (
                    <Dropdown.Item
                      text="SCORM Lesson"
                      onClick={() => this.setState({ showSCORMUploadModal: true })}
                    />
                    )}
                    {this.state.video_lessons_enabled && (
                    <Dropdown.Item
                      text="Video-based Lesson"
                      onClick={() => this.setState({ showCreateLessonModal: true, createLessonType: VIDEO })}
                    />
                    )}
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown
                  closeOnChange={false}
                  button
                  text="Options"
                  options={[
                    {
                      text: (
                        <Checkbox label="Pop-up Preview" toggle checked={popupPreview} onMouseDown={this.togglePopup} />
                      ),
                      key: 'popupPreview',
                    },
                  ]}
                />

              </div>
            </div>
            <LessonsList
              key={this.state.lessonListKey}
              showAllLessons
              showActions
              showTags={regrettably}
              popupPreview={popupPreview}
            />
          </div>
        </div>

        <Modal
          open={this.state.showCreateLessonModal}
          onClose={() => {
            this.setState({ showCreateLessonModal: false });
          }}
          style={{ width: '500px' }}
          closeIcon
        >
          <Modal.Header>Create Lesson</Modal.Header>
          <Modal.Content>
            Name:
            {' '}
            <Input onChange={(e, { value }) => this.setState({ newLessonName: value })} />
          </Modal.Content>
          <Modal.Actions>
            <Button
              type="submit"
              className="gray"
              onClick={() => {
                this.setState({ showCreateLessonModal: false });
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="green"
              onClick={() => {
                this.createLesson(this.state.createLessonType);
              }}
            >
              Create
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal
          open={this.state.showSCORMUploadModal}
          onClose={() => {
            this.setState({ showSCORMUploadModal: false });
          }}
          style={{ width: '500px' }}
          closeIcon
        >
          <Modal.Header>Create Lesson</Modal.Header>
          {this.state.uploading_scorm ? (
            <Modal.Content>
              <div>
                Uploading...
                <Loader active inline />
              </div>
            </Modal.Content>
          ) : (
            <Modal.Content>
              {this.state.scorm_error ? <div style={s.error}>{this.state.scorm_error}</div> : null}
              <div>
                Name:
                {' '}
                <Input
                  error={!this.state.newLessonName}
                  onChange={(e, { value }) => this.setState({ newLessonName: value })}
                />
              </div>
              <div />
              <div>
                Choose a SCORM file to upload:
                <div />
                <input id="file-input" type="file" accept="application/zip" ref={this.fileInput} />
              </div>
              <div>
                <br />
                <em>NOTE: SCORM files must be in SCORM 2004 format</em>
              </div>
            </Modal.Content>
          )}
          <Modal.Actions>
            <Button
              type="submit"
              className="gray"
              disabled={this.state.uploading_scorm}
              onClick={() => {
                this.setState({ showCreateLessonModal: false });
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="green"
              disabled={this.state.uploading_scorm || !this.state.newLessonName}
              onClick={() => {
                this.createSCORMLesson();
              }}
            >
              Create
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

s = {
  body: {},
  container: {
    marginLeft: '20%',
    marginRight: '20%',
    marginTop: '40px',
    display: 'flex',
    flexDirection: 'row',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  error: {
    color: 'red',
    paddingBottom: '20px',
  },
};

export default Radium(LessonListContainer);
