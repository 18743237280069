import _ from 'underscore';
import moment from 'moment';
import bind from 'bind-decorator';
import React, { Component } from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import {
  Table,
  Label,
  Loader,
  Pagination,
  Dropdown,
  Input,
  Modal,
  Button,
  Progress,
  Checkbox,
  Popup,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import SumoLogger from 'sumo-logger';
import UserLessonStatus from '@/models/user_lesson_status';
import LessonStatusSummary from '@/models/lesson_status_summary';
import Lesson from '@/models/lesson';
import User from '@/models/user';
import {
  SUMO,
  CARD,
  SCORM,
  Features,
} from '../../constants';
import LessonPreviewModal from './LessonPreviewModal';

const sumoLogger = new SumoLogger({ endpoint: SUMO });

const s = {
  search: {
    margin: '0em 1em .5em 1em',
  },
  printFrame: {
    border: 'none',
    width: '100%',
    height: '500px',
  },
  score: {
    color: '#27A167',
    fontSize: '28px',
  },
};

class LessonsList extends Component {
  static propTypes = {
    user: PropTypes.object,
    limit: PropTypes.number,
    showAllLessons: PropTypes.bool,
    showActions: PropTypes.bool,
    showSearch: PropTypes.bool,
    showTags: PropTypes.bool,
    popupPreview: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      page: 1,
      recordsPerPage: 10,
    };
  }

  static defaultProps = {
    limit: null,
    showAllLessons: false,
    showActions: false,
    showSearch: true,
    showTags: false,
  };

  async componentDidMount() {
    await this.refreshList();
  }

  async refreshList() {
    const { user } = this.props;
    let statuses;

    if (user) {
      statuses = UserLessonStatus.objects()
        .filtered({ user_id: user.id, available_to_take: true })
        .sorted('last_activity', false);
    } else {
      statuses = LessonStatusSummary.objects();
    }

    if (this.props.limit) {
      statuses = await statuses.first(this.props.limit);
    } else {
      statuses = await statuses.all();
    }

    const lessons = await Lesson.objects()
      .filtered({ deleted: false, content_head: true })
      .all();

    const lateCutoff = moment().subtract(3, 'days');
    let displayRecords = _.compact(
      _.map(statuses, (status) => {
        const lesson = _.findWhere(lessons, { id: status.lesson_id });
        if (!lesson) {
          return null;
        }

        const scorify = (score) => {
          if (!score) {
            return score;
          }
          return `${(score * 100.0).toFixed(0)}`;
        };

        const localizedStartDate = moment(status.localized_start_date);
        let description = 'Active';
        if (this.props.user) {
          if (!status.current_score) {
            if (localizedStartDate.isBefore(lateCutoff)) {
              description = 'Late';
            } else {
              description = 'In Progress';
            }
          }
        }

        return {
          lesson_id: lesson.id,
          localized_start_date: localizedStartDate,
          localized_tz: status.localized_tz,
          title: lesson.name,
          status: description,
          current_score: scorify(status.current_score || status.avg_current_score),
          first_score: scorify(status.current_score || status.avg_initial_score),
          completion_rate: scorify((status.total_assignments - status.open_assignments) / status.total_assignments),
          total_assignments: status.total_assignments,
          completed_assignments: status.total_assignments - status.open_assignments,
          last_review: status.last_reviewed_at,
          tags: lesson.tags,
          lesson_type: lesson.lesson_type,
        };
      }),
    );

    if (this.props.showAllLessons) {
      const moreDisplayRecords = _.compact(
        _.map(lessons, (lesson) => {
          const status = _.findWhere(statuses, { lesson_id: lesson.id });
          if (status) {
            return null;
          }
          return {
            lesson_id: lesson.id,
            localized_start_date: null,
            localized_tz: null,
            title: lesson.name,
            status: lesson.published ? 'Published' : 'Draft',
            current_score: null,
            first_score: null,
            last_review: null,
            tags: lesson.tags,
            lesson_type: lesson.lesson_type,
          };
        }),
      );
      if (moreDisplayRecords.length > 0) {
        displayRecords = displayRecords.concat(moreDisplayRecords);
      }
      displayRecords.sort((a, b) => {
        const aTitle = a.title ? a.title.toLowerCase() : '';
        const bTitle = b.title ? b.title.toLowerCase() : '';

        if (aTitle < bTitle) {
          return -1;
        }
        if (aTitle > bTitle) {
          return 1;
        }

        return 0;
      });
    }
    const me = await User.me();

    this.setState(
      {
        displayRecords,
        allRecords: displayRecords,
        lessons,
        me,
      },
      () => this.changePage(1),
    );
  }

  @bind
  changePage(page) {
    const { displayRecords } = this.state;
    const showPagination = displayRecords.length > this.state.recordsPerPage;

    this.setState({
      loading: false,
      page,
      showPagination,
      showLessonEditor: false,
    });
  }

  @bind
  doSearch(e, { value }) {
    const term = value.trim().toLowerCase();
    if (term.length === 0) {
      this.setState({ displayRecords: this.state.allRecords });
      return;
    }
    const results = _.filter(
      this.state.allRecords,
      (record) => record.title.toLowerCase().indexOf(term) >= 0,
    );
    this.setState({
      displayRecords: results,
    });
  }

  @bind
  async updateLesson() {
    let tags = this.state.editLessonRawTags.split(',');
    tags = _.compact(
      _.map(tags, (tag) => {
        const t = tag.trim();
        if (!t.length) {
          return null;
        }
        return t;
      }),
    );
    const name = this.state.editLessonName.trim();
    await Lesson.objects().update(this.state.selectedRecord.lesson_id, { name, tags });
    await this.refreshList();
  }

  @bind
  showEditModal(record) {
    const rawTags = record.tags.join(', ');
    this.setState({
      showLessonEditor: true,
      selectedRecord: record,
      editLessonName: record.title,
      editLessonRawTags: rawTags,
    });
  }

  @bind
  exportLesson(record) {
    const exportLessonAck = localStorage.getItem('export_lesson_ack');
    if (exportLessonAck) {
      window.location.href = `/api/v2/cmi5/${record.lesson_id}`;
    } else {
      this.setState({
        export_lesson_id: record.lesson_id,
        showExportModal: true,
      });
    }
  }

  @bind
  doExportLesson() {
    this.setState({
      showExportModal: false,
    });
    const lessonId = this.state.export_lesson_id;
    window.location.href = `/api/v2/cmi5/${lessonId}`;
  }

  @bind
  setExportLessonAckCookie(checked) {
    if (checked) {
      localStorage.setItem('export_lesson_ack', true);
    } else {
      localStorage.removeItem('export_lesson_ack');
    }
  }

  @bind
  cloneLesson(lesson) {
    this.setState({
      showEditWarningModal: null,
      showCloneModal: true,
      clonedLessonName: `CLONE  ${lesson.title}`,
      clone: lesson,
    });
  }

  @bind
  async doCloneLesson() {
    this.setState({
      showCloneModal: false,
      showCloneProgress: true,
      cloning_progress: 0,
    });
    const cloneFrom = this.state.clone;
    const dolly = await Lesson.objects().create({
      name: this.state.clonedLessonName,
      cloned_from_id: cloneFrom.lesson_id,
    });
    if (dolly.id === undefined) {
      sumoLogger.log(
        `ERROR: missing lesson_id when cloning lesson ${cloneFrom.lesson_id} in LessonsList.jsx`,
      );
    }
    this.waitForClone(dolly);
  }

  @bind
  waitForClone(dolly) {
    this.setState({ cloning_progress: this.state.cloning_progress + 1 });
    setTimeout(() => {
      if (this.state.cloning_progress >= 100) {
        this.setState({ showCloneProgress: false });
        window.location.href = `/app/v2/lessons/${dolly.id}/editor`;
      } else {
        this.waitForClone(dolly);
      }
    }, 100);
  }

  @bind
  async doCreateNewDraft(record) {
    this.setState({ draftCreating: true });
    const lesson = await Lesson.objects().filtered({ id: record.lesson_id }).first();
    const newRevision = await lesson.new_revision();

    window.location = `/app/v2/lessons/${newRevision.id}/editor`;
  }

  @bind
  menuOptionsForLesson(record) {
    if (record.lesson_type === SCORM) return null;
    const { me } = this.state;
    const { popupPreview } = this.props;
    const options = [
      <Dropdown.Item
        text="Clone"
        onClick={() => {
          this.cloneLesson(record);
        }}
      />,
      <Dropdown.Item
        text="Edit"
        onClick={() => {
          const editable = !_.findWhere(this.state.lessons, { id: record.lesson_id }).published;
          if (editable) window.location.href = `/app/v2/lessons/${record.lesson_id}/editor`;
          else this.setState({ showEditWarningModal: record, draftCreating: false });
        }}
      />,
      popupPreview ? (
        <Dropdown.Item
          text="Preview"
          onClick={() => {
            this.setState({ previewLessonId: record.lesson_id });
          }}
        />
      ) : (
        <Dropdown.Item
          as={Link}
          to={`/app/v2/lessons/${record.lesson_id}/player/preview`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Preview
        </Dropdown.Item>
      ),
    ];
    if (record.lesson_type === CARD) {
      options.push(
        <Dropdown.Item
          text="Print..."
          onClick={() => {
            this.setState({
              showPrintModal: true,
              printLessonId: record.lesson_id,
            });
          }}
        />,

      );
      if (me.waffle_flags.includes(Features.CMI5_EXPORT)) {
        options.push(
          <Dropdown.Item
            text="Export"
            onClick={() => {
              this.exportLesson(record);
            }}
          />,
        );
      }
    }
    return options;
  }

  @bind
  menuForLesson(lesson) {
    const options = this.menuOptionsForLesson(lesson);
    if (!options) {
      return null;
    }
    return (
      <Dropdown icon="ellipsis vertical" floating labeled className="icon">
        <Dropdown.Menu>
          {options}
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  render() {
    if (this.state.loading) {
      return <Loader active={this.state.loading} inline="centered" />;
    }
    const { displayRecords } = this.state;

    const start = (this.state.page - 1) * this.state.recordsPerPage;
    const end = start + this.state.recordsPerPage;
    const totalPages = Math.ceil(displayRecords.length / this.state.recordsPerPage);
    const records = displayRecords.slice(start, end);

    return (
      <div>
        {this.props.showSearch ? (
          <Input
            style={s.search}
            icon="search"
            iconPosition="left"
            placeholder="Search Lessons..."
            onChange={_.debounce(this.doSearch, 300)}
            size="huge"
            transparent
            fluid
          />
        ) : null}
        <Table celled striped>
          <Table.Header>
            <Table.Row>
              {this.props.showAllLessons ? null : <Table.HeaderCell>Active</Table.HeaderCell>}
              <Table.HeaderCell>Lesson Name</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>
                {this.props.user ? '' : 'Avg. '}
                Initial Score
              </Table.HeaderCell>
              <Table.HeaderCell>
                {this.props.user ? '' : 'Avg. '}
                Current Score
              </Table.HeaderCell>
              <Table.HeaderCell>Completion Rate</Table.HeaderCell>
              {this.props.showActions ? <Table.HeaderCell>Actions</Table.HeaderCell> : null}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {records.map((record) => {
              if (record.lesson_id === undefined) {
                sumoLogger.log(
                  `ERROR: missing lesson_id for UserLessonStatus ${record.id} in LessonsList.jsx`,
                );
              }
              return (
                <Table.Row>
                  {this.props.showAllLessons ? null : (
                    <Table.Cell>{record.localized_start_date.fromNow()}</Table.Cell>
                  )}
                  <Table.Cell>
                    <Link to={`/app/v2/lessons/${record.lesson_id}`}>{record.title}</Link>
                    &nbsp;&nbsp;
                    {this.props.showTags ? (
                      <Label
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.showEditModal(record)}
                      >
                        {' '}
                        {record.tags.length}
                        {' '}
                        tags
                        {' '}
                      </Label>
                    ) : null}
                    {record.lesson_type === SCORM ? <Label color="blue">SCORM</Label> : null}
                  </Table.Cell>
                  <Table.Cell>{record.status}</Table.Cell>
                  <Table.Cell>
                    <span style={s.score}>{record.first_score || ''}</span>
                  </Table.Cell>
                  <Table.Cell>
                    <span style={s.score}>{record.current_score || ''}</span>
                  </Table.Cell>
                  <Table.Cell>
                    <Popup
                      content={`${record.completed_assignments}/${record.total_assignments}`}
                      trigger={<span style={s.score}>{record.completion_rate || ''}</span>}
                    />
                  </Table.Cell>
                  {this.props.showActions ? (
                    <Table.Cell>{this.menuForLesson(record)}</Table.Cell>
                  ) : null}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        {!this.state.showPagination ? null : (
          <Pagination
            activePage={this.state.page}
            totalPages={totalPages}
            onPageChange={(e, { activePage }) => this.changePage(activePage)}
            size="small"
          />
        )}

        <Modal
          open={this.state.showLessonEditor}
          onClose={() => {
            this.setState({ showLessonEditor: false });
          }}
          style={{ width: '500px' }}
          closeIcon
        >
          <Modal.Header>Update Lesson</Modal.Header>
          <Modal.Content>
            Name:
            <br />
            <Input
              value={this.state.editLessonName}
              onChange={(e, { value }) => this.setState({ editLessonName: value })}
            />
            <br />
            <br />
            Tags (comma separated):
            <br />
            {' '}
            <Input
              value={this.state.editLessonRawTags}
              onChange={(e, { value }) => this.setState({ editLessonRawTags: value })}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              type="submit"
              className="gray"
              onClick={() => {
                this.setState({ showLessonEditor: false });
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="green"
              onClick={() => {
                this.updateLesson();
              }}
            >
              Update
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal
          open={this.state.showEditWarningModal}
          onClose={() => this.setState({ showEditWarningModal: false })}
          style={{ width: '500px' }}
        >
          <Modal.Header>Lesson already published</Modal.Header>
          <Modal.Content>
            You are attempting to edit an already published lesson. To ensure the best experience possible for
            your learners we instead recommend that you create a
            { ' ' }
            <strong>new draft</strong>
            { ' ' }
            (unpublished) version of this lesson.
            You will be able to edit this draft version as much as you need, and then
            { ' ' }
            <strong>publish</strong>
            { ' ' }
            a replacement of this lesson for your learners.
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="grey"
              onClick={() => {
                this.setState({
                  showEditWarningModal: false,
                });
              }}
            >
              Cancel
            </Button>
            <Button
              positive
              onClick={() => {
                this.doCreateNewDraft(this.state.showEditWarningModal);
              }}
              disabled={this.state.draftCreating}
              loading={this.state.draftCreating}
            >
              New Draft
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal
          open={this.state.showCloneModal}
          onClose={() => {
            this.setState({ showCloneModal: false });
          }}
          style={{ width: '500px' }}
          closeIcon
        >
          <Modal.Header>Clone Lesson</Modal.Header>
          <Modal.Content>
            Name:
            <br />
            <Input
              value={this.state.clonedLessonName}
              style={{ width: '100%' }}
              onChange={(e, { value }) => this.setState({ clonedLessonName: value })}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              type="submit"
              className="gray"
              onClick={() => {
                this.setState({ showCloneModal: false });
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="green"
              onClick={() => {
                this.doCloneLesson();
              }}
            >
              Clone
            </Button>
          </Modal.Actions>
        </Modal>
        <Modal
          open={this.state.showExportModal}
          onClose={() => {
            this.setState({ showExportModal: false });
          }}
          style={{ width: '500px' }}
          closeIcon
        >
          <Modal.Header>Export Lesson</Modal.Header>
          <Modal.Content>
            <p>
              You can export a Hickory lesson to cmi5 format, compatable with xAPI enabled systems.
            </p>
            <p>NOTE: Lessons exported in this way require:</p>
            <ul>
              <li>An external system compatible with xAPI & cmi5</li>
              <li>Actors with mbox ids that match email addresses in Hickory</li>
              <li>An active license of Hickory</li>
            </ul>
            <br />
            <Checkbox
              label="Do not show me this again"
              onClick={(e, { checked }) => {
                this.setExportLessonAckCookie(checked);
              }}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              type="submit"
              className="gray"
              onClick={() => {
                this.setState({ showExportModal: false });
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="green"
              onClick={() => {
                this.doExportLesson();
              }}
            >
              Export
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal open={this.state.showCloneProgress} style={{ width: '500px' }}>
          <Modal.Header>Cloning Lesson</Modal.Header>
          <Modal.Content>
            <Progress percent={this.state.cloning_progress} indicating />
          </Modal.Content>
          <Modal.Actions />
        </Modal>

        <Modal
          open={this.state.showPrintModal}
          onClose={() => {
            this.setState({ showPrintModal: false });
          }}
          closeIcon
        >
          <Modal.Header>Print Lesson</Modal.Header>
          <Modal.Content>
            <iframe
              id="printFrame"
              src={`/app/v2/lessons/${this.state.printLessonId}/print?embedded=true`}
              style={s.printFrame}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              type="submit"
              className="gray"
              onClick={() => {
                this.setState({ showPrintModal: false });
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="green"
              onClick={() => {
                document.getElementById('printFrame').contentWindow.print();
              }}
            >
              Print
            </Button>
          </Modal.Actions>
        </Modal>
        <LessonPreviewModal
          lessonId={this.state.previewLessonId}
          show={!!this.state.previewLessonId}
          close={() => this.setState({ previewLessonId: null })}
        />
      </div>
    );
  }
}

export default Radium(LessonsList);
